<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark"><i class="nav-icon fas fa-users"></i> Usuários <small>Formulário de Cadastro</small></h1> 
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="nav-icon fas fa-home"></i> Home
                </router-link>
              </li>
              <li class="breadcrumb-item active"><i class="nav-icon fas fa-users"></i> Usuários</li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="card card-primary card-outline"> 
          <form id="form1" class="form-horizontal">
            <div class="card-body">
              <div class="col-xs-12 col-sm-12 col-lg-6">
                <h4>Dados do Usuário</h4>
                <div class="form-group row">
                    <label for="nome" class="col-sm-2 col-form-label">Nome</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control validate[required]" id="nome" name="nome" v-model="entity.nome" size="50" maxlength="150" placeholder="Nome">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="telefone" class="col-sm-2 col-form-label">Foto</label>
                    <div class="col-xs-6 col-sm-6 col-lg-4">
                        <image-editor v-model="entity.foto" />
                    </div>
                </div>
                <h4>Dados de Acesso</h4>
                <div class="form-group row">
                    <label for="id_grupo_usuario" class="col-sm-2 col-form-label">Perfil de Acesso</label>
                    <div class="col-xs-8 col-sm-8 col-lg-6">
                        <select-editor class="validate[required]" ajax="/api/usuario/select-perfil" data-placeholder="Selecione o Perfil de Acesso" v-model="entity.perfil">
                        </select-editor>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="email" class="col-sm-2 col-form-label">E-mail</label>
                    <div class="col-xs-8 col-sm-8 col-lg-5">
                        <input type="text" class="form-control validate[required, custom[email]]" id="email" name="email" v-model="entity.email" size="50" maxlength="150" placeholder="Email">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="senha" class="col-sm-2 col-form-label">Senha</label>
                    <div class="col-xs-5 col-sm-5 col-lg-3">
                        <input type="text" class="form-control validate[required, minSize[4]]" id="senha" name="senha" v-model="entity.senha" size="50" maxlength="50" placeholder="Senha">
                    </div>
                </div>
                <h4>Status</h4>
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label"></label>
                    <div class="col-sm-10">
                        <div class="radio">
                            <label class="form-radio form-icon">
                                <input type="radio" name="status_usuario" data-icheck="green" v-model="entity.status_usuario" v-bind:value="true" />
                                Ativo
                            </label>
                            <label class="form-radio form-icon">
                                <input type="radio" name="status_usuario" data-icheck="red" v-model="entity.status_usuario" v-bind:value="false" />
                                Inativo
                            </label>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </form>
          <div class="card-footer">
            <div class="offset-sm-1 col-sm-11">
              <button type="button" v-if="this.$route.params.id == 0" v-on:click="insertEntity" class="btn btn-success btn-social"> <i class="fas fa-check"></i> Inserir</button>
              <button type="button" v-if="this.$route.params.id != 0" v-on:click="updateEntity" class="btn btn-info btn-social"> <i class="fas fa-pencil-alt"></i> Alterar</button>
              <button type="button" v-if="this.$route.params.id != 0" v-on:click="deleteEntity" class="btn btn-danger btn-social"> <i class="fas fa-times"></i> Excluir</button>
              <button type="button" v-on:click="back" class="btn btn-default btn-social"><i class="fas fa-history"></i> Voltar</button>
            </div>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import UsuarioController from "@/controllers/usuario.controller";
import 'jquery.inputmask/dist/jquery.inputmask.bundle';

export default {
  data: function() {
    return {
      entity: {
        "id_usuario": 0,
        "sexo": "M",
        "qtd_login": 0,
        "status_usuario": true
      }
    };
  },
  mounted(){
    this.$validator.validate("#form1");
    const id = parseInt(this.$route.params.id, 10);
    this.loadEntity(id);
    $(":input").inputmask();
  },
  methods: {
    loadEntity: function(id) {

      if(id === 0)
        return;

      UsuarioController.getById(id)
      .then(response => {
        this.entity = response.data.result;
      });
    },
    insertEntity: function(event) {
      if (!this.$validator.isValid("#form1"))
          return;

      event.target.disabled = true;

      let self = this;

      UsuarioController.insert(this.entity)
      .then(response => {
        if(!response.data.success)
        {
          this.$toastr.error(response.data.error, 'Erro!', {
            timeOut: 8000,
            onHidden: function() {
              event.target.disabled = false;
           }
          });
          return;
        }

        this.$toastr.success("Dados cadastrados com sucesso.", "Aviso",
          {
            onHidden: function() {
              event.target.disabled = false;
              self.back();
            }
          }
        );
      })
      .catch(e => {
        event.target.disabled = false;
        this.$toastr.error(e, 'Erro!', {timeOut: 8000});
      });
    },
    updateEntity: function(event) {
      if (!this.$validator.isValid("#form1"))
          return;

      event.target.disabled = true;
      
      let self = this;
      
      UsuarioController.update(this.entity)
      .then(response => {
        if(!response.data.success)
        {
          this.$toastr.error(response.data.error, 'Erro!', {
            timeOut: 8000,
            onHidden: function() {
              event.target.disabled = false;
           }
          });
          return;
        }

        this.$toastr.success("Dados alterados com sucesso.", "Aviso",
        {
          onHidden: function() {
            event.target.disabled = false;
            self.back();
          }
        });
      })
      .catch(e => {
        event.target.disabled = false;
        this.$toastr.error(e, 'Erro!', {timeOut: 8000});
      });
    },
    deleteEntity: function(event) {
      event.target.disabled = true;

      let self = this;

      this.$dialogs.confirm({
          title: 'Confirmar Exclusão!',
          content: 'Deseja realmente excluir estes dados?',
          icon: 'fas fa-exclamation-triangle',
          type: 'red',
          buttons: {
              confirm: {
                  text: 'Confirmar',
                  btnClass: 'btn-red',
                  action: function(){         
                    event.target.disabled = true;

                    UsuarioController.delete(self.entity)
                    .then(response => {

                      if(!response.data.success)
                      {
                        this.$toastr.error(response.data.error, 'Erro!', {
                          timeOut: 8000,
                          onHidden: function() {
                            event.target.disabled = false;
                        }
                        });
                        return;
                      }

                      this.$toastr.success("Dados excluídos com sucesso.", "Aviso",
                        {
                          onHidden: function() {
                            self.back();
                          }
                        }
                      );
                    })
                    .catch(e => {
                      this.$toastr.error(e, 'Erro!', {timeOut: 8000});
                      event.target.disabled = false;
                    });
                  }
              },
              close: {
                text: 'Cancelar'
              }
          }
      });
      event.target.disabled = false;
    },
    back: function() {
      this.$router.push({ name: "list-usuario" });
    }
  }
};
</script>

<style scoped>
</style>